<template>
  <b-sidebar
    id="add-new-tarea-sidebar"
    :visible="isAddNewTareaSidebarActive"
    :close-sidebar="closeAddNewTareaSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-tarea-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Tarea
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <b-form-group
          label="Nombre de la tarea"
          label-for="nombre"
        >
          <b-form-input
            id="nombre"
            v-model="tarea.nombre"
            autofocus
            trim
          />
        </b-form-group>

        <b-row class="pl-1 pt-1">
          <!-- Fields: desechable -->
          <p
            class="mr-1"
            style="font-weight: bold"
          >
            ¿Pertenece a una encomienda?
          </p>
          <b-form-checkbox
            v-model="tarea.is_encomienda"
            name="check-button"
            switch
          >
            <span style="font-weight: 500">{{
              tarea.is_encomienda ? "Sí" : "No"
            }}</span>
          </b-form-checkbox>
        </b-row>

        <b-form-group
          v-if="tarea.is_encomienda"
          label="Encomienda a la que pertenece"
          label-for="nucleo"
        >
          <v-select
            v-model="selectedTarea"
            :options="tareas"
            label="nombre"
            autofocus
            trim
          /></b-form-group>
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="createTarea()"
          >
            <b-spinner
              v-if="loading"
              small
            />
            <span v-else>Guardar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="cancelAction()"
          >
            Cancelar
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BCard,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
  BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import tareaStoreModule from '@/views/work/tareas/tareaStoreModule'
import store from '@/store'
import useTareasList from '@/views/work/tareas/useTareasList'

export default {
  components: {
    BSpinner,
    BCard,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BRow,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTareaSidebarActive',
    event: 'update:is-add-new-tarea-sidebar-active',
  },
  props: {
    isAddNewTareaSidebarActive: {
      type: Boolean,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: false,
    },
    editItem: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      tarea: {
        nombre: '',
        parent_id: '',
        is_encomienda: false,
      },
      selectedTarea: null,
    }
  },
  computed: {
    loading() {
      return this.$store.state['app-tarea'].loading
    },
    tareas() {
      return this.$store.state['app-tarea'].tareas.filter(
        item => item.parent == null,
      )
    },
  },
  setup() {
    const TAREAS_APP_STORE_MODULE_NAME = 'app-tarea'

    if (!store.hasModule(TAREAS_APP_STORE_MODULE_NAME)) {
      store.registerModule(TAREAS_APP_STORE_MODULE_NAME, tareaStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(TAREAS_APP_STORE_MODULE_NAME)) store.unregisterModule(TAREAS_APP_STORE_MODULE_NAME)
    })

    const { listTareas, refetchData } = useTareasList()

    return {
      refetchData,
      listTareas,
    }
  },
  watch: {
    editItem(newVal) {
      if (newVal) {
        this.selectedTarea = this.editItem.parent
        this.tarea.nombre = newVal.nombre || ''
        this.tarea.is_encomienda = !!newVal.parent
      }
    },
  },
  methods: {
    refreshItem() {
      this.refetchData()
    },
    cancelAction() {
      this.tarea = {
        nombre: '',
        parent_id: null,
        is_encomienda: false,
      }
      this.selectedTarea = null
      this.$emit('closeDialog')
    },
    async createTarea() {
      const parentSave = this.selectedTarea
      const withoutParent = this.tarea.nombre
      const tareaObj = {
        nombre: withoutParent,
        parent_id: parentSave ? parentSave.id : 0,
        id: this.editItem && this.editItem.id ? this.editItem.id : null,
      }

      if (this.editItem && this.editItem.id) {
        // Edit item
        await this.$store.dispatch('app-tarea/editTarea', tareaObj)
      } else {
        // Create
        await this.$store.dispatch('app-tarea/addTarea', tareaObj)
        this.$emit('addTarea', tareaObj)
      }
      this.closeAddNewTareaSidebar()
      this.$emit('refetch-data')
      this.$emit('rowClicked')
      this.cancelAction()
    },
    closeAddNewTareaSidebar() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-departamento-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
